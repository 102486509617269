import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal, PrimaryButton } from '../../components';

import css from './ShareButton.module.css';
import ShareIcon from './images/share-icon.svg';

const ShareButton = props => {
  const { className, rootClassName, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  const handleShare = () => {
    if (navigator.share) {

      navigator.share({
        title: document.title,
        text: intl.formatMessage({ id: 'ShareButton.shareText' }, { website: document.title }),
        url: window.location.href
      })
        .then(() => {
          console.log("Shared successfully")
        }).catch(() => {
          console.log("Sharing is not supported");
        })
    }
  }

  return (
    <div className={css.root}
      onClick={handleShare}>
      <img src={ShareIcon} className={css.icon} />
      <p
        className={css.button}>
        <FormattedMessage id="ShareButton.share" />
      </p>
    </div>
  );
};

ShareButton.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

ShareButton.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default ShareButton;
